
import "./style.css"
import appLogo from './app-logo.png'
import appLogo2 from '../assets/img/app-logo2.png'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { RouteDefinitions } from '../routes';
import { useContext, useState } from 'react';
import { appData } from "../data";
import { Context } from 'Context';
import { useCookies } from "react-cookie";

var classNames = require('classnames');
var _values = require('lodash.values');

export const Header = (props: any) => {

  const [menuToggle, setMenuToggle] = useState(false)
  const [searchText, setSearchText] = useState("")
  const appContext = useContext(Context);
  const [opacityLower, setOpacityLower] = useState(false)
  const [cookies, setCookie] = useCookies(['language']);



  const Logo = () => {
    return <Link to={RouteDefinitions.MainPage} onClick={() => menuToggle && setMenuToggle(false)}  >
      <img src={appLogo2} alt="Ziraat Bankası Eğitim Vakfı" />
    </Link>
  }

  const SearchBox = () => {
    return <>
      <div className="search-input">
        {/* <input value={searchText} type="text" placeholder={appData[userLanguage].searchText} onChange={(event: any) => setSearchText(event.target.value)} />
        <i className="fas fa-search"></i> */}
      </div>
    </>
  }

  const headerClassName = classNames({
    "header-container": true,
    "responsive-menu-open": menuToggle,
    "low-opacity": opacityLower
  })

  return (
    <div className={headerClassName}>
      <div className="app-logo">
        {Logo()}
      </div>
      <div className="menu-container">
        <div className="login-bar">
          <div className="transparent"></div>
          <div className="link">
            <span className={appContext.userLanguage === 'tr' ? 'active' : ''} onClick={
              () => {
                setOpacityLower(true)
                setTimeout(() => {
                  setOpacityLower(false);
                  appContext.changeLanguage?.("tr")
                  setCookie('language', "tr")
                }, 300)
              }
            }>TR</span>
            <span className={appContext.userLanguage === 'en' ? 'active' : ''} style={{ borderLeft: "1px solid white" }} onClick={() => {
              setOpacityLower(true)
              setTimeout(() => {
                setOpacityLower(false);
                appContext.changeLanguage?.("en")
                setCookie('language', "en")

              }, 300)
            }}>EN</span>
          </div>
        </div>
        <div className={"menu-bar"}>
          {SearchBox()}
          {/* {SearchResults()} */}
          <div className='menu-icons'>
            {/* <i className="fas fa-search"></i> */}
            {menuToggle ? <i className="fas fa-times" onClick={() => setMenuToggle(!menuToggle)}></i> :
              <i className="fas fa-bars" onClick={() => setMenuToggle(!menuToggle)}></i>}
          </div>

          <ul>
            {appContext.data.pages.map((page: any) => {
              return !page.dontShowAsMenu && <li>
                <NavLink to={page.url} onClick={() => menuToggle && setMenuToggle(false)} >
                  {page.iconClass && <i className={page.iconClass}></i>}
                  {page.label}
                </NavLink>
              </li>
            })}
          </ul>

        </div>
      </div>
    </div >
  )
}