import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import '@zt/fonts/lib/open-sans/font.css';
import '@zt/fonts/lib/font-awesome-5/css/all.min.css';
import { BrowserRouter } from 'react-router-dom';
import { ContextProvider } from './Context';
import { CookiesProvider } from 'react-cookie';
import { CookieSettings } from './CookieSettings';
import { Header } from './Header';
import { Content } from './Pages/Container';
import { AppRoutes } from './routes';
import { Footer } from './Footer';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ContextProvider>
      <CookiesProvider defaultSetOptions={{ path: '/', maxAge: 31536000 }}>
        <div id="App" className={"ziraat-vakif-app"}>
          <CookieSettings />
          <Header />
          <Content >
            <AppRoutes />
          </Content>
          <Footer />
        </div>
      </CookiesProvider>
    </ContextProvider >
  </BrowserRouter>
);
