
import libraryImg from '../assets/img/library.jpg'
import readingGirlImg from '../assets/img/readingGirl.png'
import './main-page.css'
import { Context } from '../Context';
import { useContext } from 'react';

export const MainPage = () => {
  const appContext = useContext(Context);
  const mainPage = appContext.data.pages.find((page: any) => page.url === "/")

  return (
    <div className="mainpage-container" style={{ backgroundImage: `url(${libraryImg})` }}>
      <div className='white-layer'></div>
      <img src={readingGirlImg} alt="Ziraat Bankası Eğitim Vakfı" />
      <div className='foundation-summary'>
        {mainPage?.contents?.map((content:any) => <span>{content}</span>)}
      </div>
    </div>
  )
}