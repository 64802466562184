
import { useContext, useState } from 'react'
import { appData } from '../data'
import libraryImage from '../assets/img/vakif-bina.jpg'
import './cerez.css'
import { Context } from '../Context'
import parse from 'html-react-parser';



export const Gizlilik = () => {
  const appContext = useContext(Context);
  const contractPageContents = appContext.data.pages.find((page: any) => page.url === "gizlilik")?.contents


  const foundationData: any = contractPageContents![0];



  return (
    <div className="cookie-info-container" >
      <div className='section-title'>
        <h1>{foundationData.title}</h1>
      </div>
      <section className='foundation-section'>
        <div className="legal-page">
          <div className="section">
            <div className="box">
              {parse(foundationData?.content || "")}
            </div>
          </div>

        </div>
      </section>
    </div >
  )
}