
import { useContext, useState } from 'react'
import { appData } from '../data'
import libraryImage from '../assets/img/vakif-bina.jpg'
import './contract.css'
import { Context } from '../Context'
import { pdfjs, Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = './pdf.worker.min.js';




export const Contract = () => {
  const appContext = useContext(Context);

  const contractPageContents = appContext.data.pages.find((page: any) => page.url === "vakif-sozlesmesi")?.contents
  const [showPdf, setShowPdf] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(true)
  const [numPages, setNumPages] = useState<number>();


  const Foundation = () => {
    const foundationData: any = contractPageContents![0];
    const paragraph1Data: any = contractPageContents![1];

    const descriptions = paragraph1Data.content.split("\\");

    return <section className='foundation-section'>
      <div className='section-title'>
        <h1>{foundationData.title}</h1>
      </div>
      <p>{descriptions[0]}
        <br />
        <br />
        {
          window.innerWidth <= 768 ?
            <a href="vkf_snd.pdf">{descriptions[1]}</a> :
            <a href="#" onClick={() => setShowPdf(true)}>{descriptions[1]}
              <i className="fas fa-angle-double-right"></i>
            </a>}
      </p>
    </section>
  }


  function onDocumentLoadSuccess(a: PDFDocumentProxy): void {
    console.log("inof", a)
    setNumPages(a.numPages);
  }

  const PDFView = () => {
    const oneVhInPx = window.innerHeight / 100;

    return (
      // <div className='pdf-holder'>
      //   {pdfLoading && <div className='pdf-loading'>Sözleşme Yükleniyor...</div>}

      //   <div className='close-bar'>
      //     <i className='fas fa-times' onClick={() => setShowPdf(false)}></i>
      //   </div>
      //   <object onLoad={() => setTimeout(() => {
      //     setPdfLoading(false)
      //   }, 4000)} data="./VAKIF_SENEDİ.pdf" width="100%" height="93%">
      //   </object>
      // </div>
      <div className='pdf-holder'>
        <div className='close-bar'>
          <i className='fas fa-times' onClick={() => setShowPdf(false)}></i>
        </div>
        <div className='pdf-wrapper'>

          <Document file="./vkf_snd.pdf" onLoadSuccess={onDocumentLoadSuccess}  >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={oneVhInPx * 70}
              />
            ))}
          </Document>
        </div>
      </div>

    )
  }





  return (
    <div className="contract-container" >
      <img src={libraryImage} />
      <div className='publication-title'>

      </div>
      <div className='section-holder'>
        {Foundation()}
      </div>
      {showPdf && PDFView()}

    </div >
  )
}