
export enum LANGUAGE {
  TR = 'tr',
  EN = 'en'
}

export interface IAppData {
  [LANGUAGE.TR]: IContent,
  [LANGUAGE.EN]: IContent
}


export interface IContent {
  searchText: string,
  siteTitle: string,
  pages: Page[]
}

export interface Page {
  url: string
  label: string
  dontShowAsMenu?: boolean
  contents: any[]
  iconClass?: string
}



export const appData: IAppData = {
  tr: {
    searchText: "Arama",
    siteTitle: "Ziraat Bankası Eğitim Vakfı © 2023",
    pages: [
      {
        url: "/",
        label: "Anasayfa",
        dontShowAsMenu: true,
        contents: [
          "Medeniyetimiz, kültürümüz ve manevi değerlerimizle geleceği şekillendirecek nesiller",
          "Yeni ve milli teknolojiler üreten ekosistemler",
          "Bankacılık, finans,ziraat ve teknoloji ile ilgili alanlarda özgün modeller"
        ]
      },
      {
        url: "vizyon-gaye",
        label: "Vizyon / Gaye",
        contents: [
          { title: "Ahmet Şefik Mithat Paşa (1822-1884)", content: "Memleket Sandıkları (Pirot/1863)\\ ve Ziraat Bankası kurucusu" },
          {
            title: "Vizyon", content: `Medeniyetimiz, kültürümüz ve manevi değerlerimizle; geleceği şekillendirecek nesiller yetiştirmek,\\
          Yeni ve milli teknolojiler üretecek ekosistemler oluşturmak,\\
          Bankacılık, finans, ziraat, teknoloji ve ilgili alanlarda özgün modeller geliştirilmesi ile ülkemizin cazibe merkezi olmasına katkı sağlamak,\\
          Dünya kaynaklarını ölçülü bir şekilde kullanacak girişimlere destek olmak` },
          {
            title: "Gaye", content: "1863 yılından günümüze kadar Ziraat Bankası’nın ülkemize yapmış olduğu katkılar esnasında oluşan bilgi birikimini, ülkenin geleceği olan gençlerimizle daha fazla paylaşmak amacıyla kurulmuştur."

          }
        ]
      },
      {
        url: "vakif-sozlesmesi",
        label: "Vakıf Sözleşmesi",
        contents: [
          { title: "Vakıf Sözleşmesi", content: "Vakıf Sözleşmesi" },
          {
            title: "Vakıf Sözleşmesi", content: `Vakfımız 30.12.2022 tarih ve 16207/1-1 sayılı Resmi Gazete’de yayımlanarak faaliyetine başlamıştır. \\ Vakıf Senedine linkten ulaşabilirsiniz. `
          }
        ]
      },
      {
        url: "yayinlar",
        label: "Yayınlar",
        iconClass: "fas fa-book-reader",
        contents: [
          { title: "Yayınlar", content: "Vakfımızın kuruluş amaçlarından birisi de bankacılık, finans, ziraat, teknoloji ve spor vb diğer alanlarda bilimsel araştırma ve geliştirme projeleri gerçekleştirmek ve oluşan bilgi birikimini toplumumuzla paylaşmak üzere seminerler ve bilimsel toplantılar düzenlenmektir. " },
          { title: "", content: `Bu kapsamda düzenlenecek olan Geleceğin Bankacılığı Sempozyumu ile ilgili bilgilere \\adresinden ulaşabilirsiniz.` }
        ]
      },
      {
        url: "iletisim",
        label: "İletişim",
        contents: [
          { title: "Ziraat Bankası Eğitim Vakfı", content: "" },
          { title: "Vakıf Merkezi", content: `Hacı Bayram Mahallesi, Atatürk Bulvarı, No:8 Altındağ/Ankara` },
          { title: "Telefon", content: `(0312) 310 13 12` }
        ]
      },
      {
        url: "cerez-politikasi",
        label: "Çerez Politikası",
        dontShowAsMenu: true,
        contents: [
          {
            title: "Çerez Politikası", content: `   <h3>Genel</h3>
          <p>Kullanılmakta olan internet tarayıcısı aracılığı ile internet ağ sunucusu tarafından kullanıcıların cihazlarına gönderilen küçük veri dosyaları çerez olarak anılmakta olup, internet siteleri bu çerezler vasıtası ile kullanıcıları tanımaktadır. Çerezlerin ömrü tarayıcı ayarlarına bağlı olarak farklılaşmaktadır.</p>
          <p>Bu çerezler, Şirket tarafından yönetilmekte olan sistemler aracılığıyla oluşturulmakla birlikte, aynı zamanda Şirket tarafından yetkilendirilen bazı hizmet sağlayıcılar kullanıcıların cihazlarına benzeri teknolojiler yerleştirerek IP adresi, benzersiz tanımlayıcı ve cihaz tanımlayıcı bilgileri edinebilmektedir. Ayrıca, Şirket sistemlerinde bulunan üçüncü taraflara ait linkler, bu üçüncü taraflara ait gizlilik politikalarına tabi olmakla birlikte, gizlilik uygulamalarına ait sorumluluk Şirket’e ait olmamaktadır ve bu bağlamda ilgili link kapsamındaki site ziyaret edildiğinde siteye ait gizlilik politikasının okunması önerilmektedir.</p>
  
          <h3>Çerez Türleri</h3>
          <p>Ana kullanım amacı kullanıcılara kolaylık sağlamak olan çerezler, temel olarak 4 ana grupta toplanmaktadır:</p>
  
          <ul className="list">
            <li>Oturum Çerezleri: İnternet sayfaları arasında bilgi taşınması ve kullanıcı tarafından girilen bilgilerin sistemsel olarak hatırlanması gibi çeşitli özelliklerden faydalanmaya olanak sağlayan çerezlerdir ve Şirket internet sitesine ait fonksiyonların düzgün bir şekilde işleyebilmesi için gereklidir.</li>
            <li>Performans Çerezleri: Sayfaların ziyaret edilme frekansı, olası hata iletileri, kullanıcıların ilgili sayfada harcadıkları toplam zaman ile birlikte siteyi kullanım desenleri konularında bilgi toplayan çerezlerdir ve Şirket internet sitesinin performansını arttırma amacıyla kullanılmaktadır.</li>
            <li>Fonksiyonel Çerezler: Kullanıcıya kolaylık sağlanması amacıyla önceden seçili olan seçeneklerin hatırlatılmasını sağlayan çerezlerdir ve Şirket internet sitesi kapsamında kullanıcılara gelişmiş internet özellikleri sağlanmasını hedeflemektedir.</li>
            <li>Reklam Ve Üçüncü Taraf Çerezleri: Üçüncü parti tedarikçilere ait çerezlerdir ve Şirket internet sitesindeki bazı fonksiyonların kullanımına ve reklam takibinin yapılmasına olanak sağlamaktadır.</li>
          </ul>
  
  
          <h3>Çerezlerin Kullanım Amaçları</h3>
          <p>Şirketimiz tarafından kullanılmakta olan çerezlere ait kullanım amaçları aşağıdaki gibidir:</p>
  
          <ul className="list">
            <li>Operasyonel amaçlı kullanımlar: Şirketimiz, sistemlerinin idaresi ve güvenliğinin sağlanması amacıyla, bu sitedeki fonksiyonlardan yararlanmayı sağlayan veyahut düzensiz davranışları tespit eden çerezler kullanabilmektedir.</li>
            <li>İşlevselliğe yönelik kullanımlar: Şirketimiz, sistemlerinin kullanımını kolaylaştırmak ve kullanıcı özelinde kullanım özellikleri sağlamak amacıyla, kullanıcıların bilgilerini ve geçmiş seçimlerini hatırlatan çerezler kullanabilmektedir.</li>
            <li>Performansa yönelik kullanımlar: Şirketimiz, sistemlerinin performansının artırılması ve ölçülmesi amacıyla, gönderilen iletilerle olan etkileşimi ve kullanıcı davranışlarını değerlendiren ve analiz eden çerezler kullanabilmektedir.</li>
            <li>Reklam amaçlı kullanımlar: Şirketimiz, kendine veya üçüncü taraflara ait sistemlerin üzerinden kullanıcıların ilgi alanları kapsamında reklam ve benzeri içeriklerin iletilmesi amacıyla, bu reklamların etkinliğini ölçen veya tıklanma durumunu analiz eden çerezler kullanabilmektedir.</li>
          </ul>
  
          <h3>Çerezleri Devre Dışı Bırakmak</h3>
          <p>Çerez kullanımı birçok tarayıcıda önceden tanımlı bir şekilde seçili olarak bulunmaktadır ve kullanıcılar bu seçim durumunu tarayıcı ayarlarından değiştirebilmekte ve dolayısıyla mevcut çerezleri silip ileriki çerez kullanımlarını da reddedebilmektedir; nitekim çerez kullanımının iptal edilmesi halinde, Şirket sistemlerindeki bir takım özelliklerden faydalanılamaması söz konusu olabilmektedir.</p>
          <p>Çerez kullanım seçiminin değiştirilmesine ait yöntem, tarayıcı tipine bağlı olarak değişmekte olup, ilgili hizmet sağlayıcıdan dilendiği zaman öğrenilebilmektedir.</p>
  
          <h3>Web Sitesinde Bulunan Bilgi ve Materyal</h3>
          <p>Şirketimiz web sitesinde bulunan bilgi, materyal ve bunların düzenlenmesi konusundaki telif hakları yine Şirketimize aittir. Web sitemizde yer alan ve üçüncü şahıslara ait materyaller dışında kalan bilgi ve materyale dair tüm telif hakları, tescilli marka, patent, fikri ve sair mülkiyet hakları Şirketimizde saklıdır.</p>`,
          },
          {
            title: "Vakıf Sözleşmesi", content: `Vakfımız 30.12.2022 tarih ve 16207/1-1 sayılı Resmi Gazete’de yayımlanarak faaliyetine başlamıştır. \\ Vakıf Senedine linkten ulaşabilirsiniz. `
          }
        ]
      },
      {
        url: "gizlilik",
        label: "Gizlilik Politikası",
        dontShowAsMenu: true,
        contents: [
          {
            title: "Gizlilik Politikası", content: `<p>Ziraat Teknoloji A.Ş ilgili yasal mevzuatlar çerçevesinde müşteri bilgilerinin gizliliğini ve güvenliğini en üst seviyede sağlamak konusunda gerekli tedbirleri almakta ve bu doğrultuda Şirketimiz web sitesinde ve internet şubesinde aşağıda belirtilen hususları uygulamaktadır.</p>

            <ul className="list">
              <li>Ziraat Teknoloji A.Ş. faaliyetlerini gerçekleştirebilmek ve hizmetlerinin kesintisiz ilerleyebilmesini sağlamak adına, Genel Müdürlük, çağrı merkezi gibi kanallardan; sözlü, yazılı ya da elektronik ortam üzerinden temin edebildiği kişisel verileri, Veri Sorumlusu sıfatıyla, hukuka uygun bir biçimde işlemektedir.</li>
              <li>Şirketimiz, kişisel verilerin gizliliğini ve güvenliğini korumaya önem verir. Bu doğrultuda, kişisel verileri yetkisiz erişim, zarar, kayıp veya ifşaya karşı korumak için gerekli teknik ve idari güvenlik önlemleri alınır. Şirket, web sitesi üzerinden diğer web sitelerine erişim için verilen bağlantıların güvenlik ve gizliliğinden sorumlu değildir. Bu sitelere giriş sonucu karşılaşılabilecek maddi veya manevi kayıplarda sorumluluk kabul edilmeyecektir.</li>
              <li>Şirket web sitesi üzerinden ürün ve hizmet satışı/başvurusu için talep edilen veya Şirketimiz sisteminde var olan müşterilere ait her türlü kişisel bilgi, müşteri onayı olmadan 3. Kişilerle hiçbir şekilde paylaşılmamaktadır. Yalnızca müşteri bilgilerine erişme yetkisi bulunan yasal kurumlar ve yetkililer ile talep edilmesi durumunda müşteri bilgileri paylaşılabilecektir.</li>
              <li>Müşterilerimizin web sitesi üzerinden girmiş oldukları bilgilere 3. kişilerin erişimi engellenmiştir. Müşterilerimizin kişisel bilgilerinin gizliliğini korumak amacıyla Şirketimiz sistem ve internet alt yapısı en güvenilir seviyede tutularak gerekli önlemler alınmıştır.</li>
              <li>Şirketimiz, gerekli gördüğü durumlarda farklı kuruluşlardan destek hizmeti almakta ve ilgili kuruluşların Şirketin gizlilik standartlarına ve şartlarına uygun hareket etmesini sağlamaktadır. Şirketimiz, sözleşme düzenlediği veri işleyenlerin bilgi güvenliğine en az kendisi kadar önem verdiklerinden ve müşterek sorumluluğun bilinciyle hareket ettiklerinden emin olmakta ve bunu sözleşmesel olarak da güvenceye almaktadır. Veri işleyenler, mevzuattaki tanım ile paralel olarak yalnız Ziraat Teknoloji'nın talimatları doğrultusunda, Ziraat Teknoloji ile akdedilmiş sözleşme çevçevesinde kalmak suretiyle ve mevzuata uygun olarak kişisel verileri işler.</li>
              <li>Şirketimiz web sitesinde bulunan bilgi, materyal ve bunların düzenlenmesi konusundaki telif hakları, Ziraat Teknoloji A.Ş' ye aittir. Şirketimiz web sitesinde yer alan üçüncü şahıslara ait materyaller dışında kalan bilgi ve materyallere dair tüm telif hakları, tescilli marka, patent, entelektüel ve diğer mülkiyet hakları Şirketimizde saklıdır.</li>
            </ul>
            <p>Şirketimiz web sitesine müşterilerimizin/ziyaretçilerimizin giriş yapması, yukarıda belirtilen koşulları kabul ettiği anlamına gelmekte olup, Şirketimiz bu yasal uyarıda yer alan koşulları ve hükümleri, önceden bir ihbara gerek kalmaksızın değiştirme ve güncelleme hakkına sahiptir.</p>`,
          }
        ]
      },
    ],
  },
  en: {
    searchText: "Search",
    siteTitle: "Ziraat Bankası Education Foundation © 2023",
    pages: [
      {
        url: "/",
        label: "Home",
        dontShowAsMenu: true,
        contents: [
          "Generations that will shape the future with our civilization, culture and spiritual values",
          "Ecosystems that produce new and national technologies",
          "Original models in fields related to banking, finance, agriculture and technology"
        ]
      },
      {
        url: "vizyon-gaye",
        label: "Vision / Goal",
        contents: [
          { title: "Ahmet Şefik Mithat Pasha (1822-1884)", content: "Founder of Ziraat Bankası and \\ Author of 'Memleket Sandıkları' (Pirot/1863)" },
          {
            title: "Vision", content: `Nurturing future generations capable of shaping the future with our civilization, culture, and spiritual values,\\
            	Building ecosystems aimed at generating new and national technologies,\\
              Contributing to making our country a center of attraction by developing unique models in banking, finance, agriculture, technology, and related fields,\\
              Supporting initiatives that use global resources in a measured manner`
          },
          {
            title: "Goal", content: "Our foundation was established to further share the knowledge in the course of  Ziraat Bank’s contributions to our country since 1863, with our youth who are the future of our country"
          }
        ]
      },
      {
        url: "vakif-sozlesmesi",
        label: "Foundation Charter",
        contents: [
          {
            title: "Foundation Charter",
            content: "Foundation Charter"
          },
          {
            title: "Foundation Charter",
            content: `Our foundation commenced its activities as published in the Official Gazette with issue number 16207/1-1 dated 30.12.2022.\\ You can access the Foundation Charter through the following link`
          }
        ]

      },
      {
        url: "yayinlar",
        label: "Publications",
        iconClass: "fas fa-book-reader",
        contents: [
          { title: "Publications", content: "One of the aims of our foundation is to conduct scientific research and development projects in banking, finance, agriculture, technology, sports, and other fields, and to share the resulting knowledge with our society through seminars and scientific meetings. " },
          { title: "", content: `In this context, information about the Future of Banking Symposium can be accessed at  \\` }
        ]
      },
      {
        url: "iletisim",
        label: "Contact",
        contents: [
          { title: "Ziraat Bank Education Foundation", content: "" },
          { title: "Foundation Center", content: `Hacı Bayram Neighborhood, Atatürk Boulevard, No:8 Altındağ/Ankara` },
          { title: "Phone", content: `(0312) 310 13 12` }
        ]
      },
      {
        url: "cerez-politikasi",
        label: "Cookie Policy",
        dontShowAsMenu: true,
        contents: [
          {
            title: "Cookie Policy", content: `<h3>General</h3>
            <p>Small data files sent to users' devices by the internet server through the currently used web browser are referred to as cookies, and websites use these cookies to recognize users. The lifespan of cookies varies depending on browser settings.</p>
            <p>While these cookies are created through systems managed by the Company, some authorized service providers, also approved by the Company, can place similar technologies on users' devices to obtain IP addresses, unique identifiers, and device identification information. Additionally, links to third-party sites within the Company's systems are subject to the privacy policies of those third parties. The responsibility for privacy practices in this context does not belong to the Company, and it is recommended to read the privacy policy of the site when visiting it through the relevant link.</p>
            
            <h3>Types of Cookies</h3>
            <p>Cookies, primarily designed to provide convenience to users, are generally categorized into 4 main groups:</p>
            
            <ul className="list">
              <li>Session Cookies: Cookies that enable various features such as carrying information between web pages and remembering information entered by the user systemically, necessary for the proper functioning of functions on the Company's website.</li>
              <li>Performance Cookies: Cookies that gather information about the frequency of page visits, possible error messages, and user usage patterns on the relevant page, used to enhance the performance of the Company's website.</li>
              <li>Functional Cookies: Cookies that remind users of pre-selected options to provide convenience, aiming to offer advanced internet features to users within the scope of the Company's website.</li>
              <li>Advertising and Third-Party Cookies: Cookies belonging to third-party suppliers that allow the use of certain functions on the Company's website and enable tracking of advertisements.</li>
            </ul>
            
            <h3>Purposes of Cookie Usage</h3>
            <p>The purposes of using cookies by our company are as follows:</p>
            
            <ul className="list">
              <li>Operational Uses: Our company may use cookies to administer and secure its systems, enabling the use of functions on this site or detecting irregular behaviors.</li>
              <li>Functionality-Related Uses: Our company may use cookies to facilitate the use of its systems and provide usage features specific to users, remembering users' information and past choices.</li>
              <li>Performance-Related Uses: Our company may use cookies to enhance and measure the performance of its systems, evaluating and analyzing interactions with sent messages and user behaviors.</li>
              <li>Advertising-Related Uses: Our company may use cookies to transmit advertisements and similar content within the scope of users' interests through its own or third-party systems, measuring the effectiveness of these ads or analyzing click-through rates.</li>
            </ul>
            
            <h3>Disabling Cookies</h3>
            <p>Cookie usage is preselected in many browsers, and users can change this selection status from browser settings, thus deleting existing cookies and rejecting future cookie usage. However, if cookie usage is canceled, certain features on the Company's systems may not be available.</p>
            <p>The method of changing the cookie usage choice varies depending on the browser type and can be learned from the relevant service provider when desired.</p>
            
            <h3>Information and Material on the Website</h3>
            <p>The copyright of information, material, and their arrangement on our company's website belongs to our company. All copyright, registered trademark, patent, intellectual, and other property rights for information and material not owned by third parties on our website are reserved by our company.</p>
            `,
          },
          {
            title: "Vakıf Sözleşmesi", content: `Vakfımız 30.12.2022 tarih ve 16207/1-1 sayılı Resmi Gazete’de yayımlanarak faaliyetine başlamıştır. \\ Vakıf Senedine linkten ulaşabilirsiniz. `
          }
        ]
      },
    ]
  }

}