import { useContext, useState } from 'react';
import { appData } from '../data';
import MapPng from '../assets/img/map.png'
import { Context } from '../Context';
import '../assets/scss/contact.scss'
export const Contact = () => {

  const appContext = useContext(Context);
  const contactPage = appContext.data.pages.find((page: any) => page.url === "iletisim")

  return (
    <div className="contact-page">
      <div className="contact-page__maps">
        <a href="https://maps.app.goo.gl/11V9yqPTSAJNf6Jj6" target="_blank">
          <img src={MapPng} alt="" data-aos="fade-in" />
        </a>
      </div>


      <div className="row gx-6">
        <div className="col-12 col-sm-6">
          <div className="contact-page__address">
            <h1>{contactPage?.label}</h1>
            <div className="contact-page__address__item">
              <h2>{contactPage?.contents?.[0].title}</h2>
              <h3>{contactPage?.contents?.[1].title}</h3>
              <p>{contactPage?.contents?.[1].content} </p>
              <h3>{contactPage?.contents?.[2].title}</h3>
              <p>{contactPage?.contents?.[2].content}</p>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}