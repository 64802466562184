import { Route, Routes } from 'react-router-dom';
import { MainPage } from './Pages/MainPage';
import { Vision } from './Pages/Vision';
import { Contract } from './Pages/Contract';
import { Publication } from './Pages/Publication';
import { Contact } from './Pages/Contact';
import { Cerez } from './Pages/Cerez';
import { Gizlilik } from './Pages/Gizlilik';


export enum RouteDefinitions {
  MainPage = "/",
  Vision = "/vizyon-gaye",
  Contract = "/vakif-sozlesmesi",
  Publication = "/yayinlar",
  Contact = "/iletisim",
  Cerez = "/cerez-politikasi",
  Gizlilik = "/gizlilik-politikasi",
}

export const AppRoutes = () => {

  return (
    <Routes>
      <Route path='/' element={<MainPage />} />
      <Route path='/vizyon-gaye' element={<Vision />} />
      <Route path='/vakif-sozlesmesi' element={<Contract />} />
      <Route path='/yayinlar' element={<Publication />} />
      <Route path='/iletisim' element={<Contact />} />
      <Route path='/cerez-politikasi' element={<Cerez />} />
      <Route path='/gizlilik-politikasi' element={<Gizlilik />} />
    </Routes>
  );
};