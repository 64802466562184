
import "./style.scss"

export const Content = ({ children }: any) => {

  return (
    <div className="content-container">
      {children}
    </div>
  )
}