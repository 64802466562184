
import { useContext } from 'react'
import libraryImage from '../assets/img/library.png'
import './publication.css'
import { Context } from '../Context'

export const Publication = () => {

  const appContext = useContext(Context);
  const publicationPageContents = appContext.data.pages.find((page: any) => page.url === "yayinlar")?.contents

  const Foundation = () => {
    const foundationData: any = publicationPageContents![0];

    return <section className='foundation-section'>
      <div className='section-title'>
        <h1>{foundationData.title}</h1>
      </div>
      <p>{foundationData.content}</p>
    </section>
  }

  const Paragraph1 = () => {
    const paragraph1Data: any = publicationPageContents![1];
    const descriptions = paragraph1Data.content.split("\\");

    return <section className='paragraph1-section'>

      <p>
        <span>{descriptions[0]}</span>
        <a href='https://geleceginbankaciligi.com.tr' target='_blank'>https://geleceginbankaciligi.com.tr</a>
        <span> {descriptions[1]}</span>
      </p>
    </section>
  }



  return (
    <div className="publication-container"
    // style={{ backgroundImage: `url(${libraryImage})`, backgroundPosition: "bottom" }}
    >
      <img src={libraryImage} />
      <div className='publication-title'>
      </div>
      <div className='section-holder'>
        {Foundation()}<br /><br />
        {Paragraph1()}
      </div>
    </div >
  )
}