
import { useContext } from "react";
import "./style.css"
import { Context } from '../Context'
import { appData } from "../data";
import { Link } from "react-router-dom";
import { RouteDefinitions } from "../routes";

export const Footer = () => {
  const appContext = useContext(Context);
  const contractPageContents = appContext.data.pages.find((page: any) => page.url === "cerez-politikasi")?.contents
  const foundationData: any = contractPageContents![0];


  return (
    <div className="footer-container">
      <Link to={RouteDefinitions.Cerez}>{foundationData.title}</Link>
      {appContext.data.siteTitle}
    </div>
  )
}