import './vision.css'
import mithatpasaImg from '../assets/img/mithatpasa.png'
import { appData } from '../data'
import { useContext, useEffect, useRef } from 'react'
import { Context } from '../Context'


export const Vision = () => {
  const appContext = useContext(Context);
  const visionPageContents = appContext.data.pages.find((page: any) => page.url === "vizyon-gaye")?.contents

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const MithatPasa = () => {
    const mithatPasaData: any = visionPageContents![0];
    const [description1, description2] = mithatPasaData.content.split("\\")

    return <section className='mithatpasa-section'>
      <div className='section-title'>
        <h1>Mithat Paşa</h1>
      </div>
      <img src={mithatpasaImg} />
      <p>
      </p>

      <div className='mithatpasa-description'>
        <span><b>{mithatPasaData.title}</b></span>
        <span>{description1}</span>
        <span>{description2}</span>
      </div>
    </section>
  }

  const Vision = () => {
    const visionData: any = visionPageContents![1];
    const descriptions = visionData.content.split("\\")

    return <section className='vision-section'>

      <div className='section-title'>
        <h1>{visionData.title}</h1>
      </div>
      <p>
        <ul>
          <li><i className="fal fa-check-circle"></i><span>{descriptions[0]}</span></li>
          <li><i className="fal fa-check-circle"></i><span>{descriptions[1]}</span></li>
          <li><i className="fal fa-check-circle"></i><span>{descriptions[2]}</span></li>
          <li><i className="fal fa-check-circle"></i><span>{descriptions[3]}</span></li>
        </ul>
      </p>
    </section>
  }


  const Mission = () => {
    const missionData: any = visionPageContents![2];

    return <section className='mission-section'>
      <div className='section-title'>
        <h1>{missionData.title}</h1>
      </div>
      <p>{missionData.content}</p>
    </section>
  }

  return (
    <div className="vision-container">
      {Mission()}
      {Vision()}
      {MithatPasa()}
    </div >
  )
}